import React from "react";

import ContactForm from "../../../../ContactForm";

const HVO100Contact = ({ name = "hvo100" }) => {
    return (
        <ContactForm
            urlEnding="hvo100"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
        />
    );
};

export default HVO100Contact;
